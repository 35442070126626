(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
(function (global){(function (){
var component = (function () {
  var doc = global.document || null;
  var $elem = $('[data-animate]'),
    defaultAnimation = require('./default-animate').defaultAnimation;
  if ($elem.length > 0) {
    var Animate = function ($component) {
      var self = {
        init: function () {
          var sceneOptions = {
            reverse: $component.data('reverse') || false,
          }
          if (!$component.data('offset')) {
            sceneOptions.triggerElement = $component.data('trigger-element') || $component[0];
          } else {
            sceneOptions.offset = $component.data('offset');
          }
          var controller = new ScrollMagic.Controller();
          var scene = new ScrollMagic.Scene(sceneOptions);
          if ($component.data('toggle-class')) {
            var cssClass = $component.data('toggle-class');
            scene = scene.on('enter', function (event) { $component.addClass(cssClass) })
              .on('leave', function (event) { $component.removeClass(cssClass) })
          }
          else if ($component.data('default-animate')) {
            defaultAnimation.targets = $component[0];
            scene = scene.on('enter', function (event) {
              anime(defaultAnimation);
            })
          }
          scene.addTo(controller);
          return;
        },
        unload: function () {
          return;
        }
      }
      return self;
    };
    for (var i = 0; i < $elem.length; i++) {
      var $item = $($elem[i]);
      var animate = new Animate($item);
      animate.init();
    }

  }
})();

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"./default-animate":2}],2:[function(require,module,exports){
var animationTime = 700;
var defaultAnimation = {
  duration: 1000,
  translateY: 400,
  opacity: {
    value: [0, 1],
    duration: animationTime,
    easing: 'linear'
  },
  translateY: {
    value: [200, 0],
    duration: animationTime,
    elasticity: 400,
    easing: 'easeInOutBack'
  },
};
var bouncyAnimation = {
  duration: 1200,
  elasticity: 700,
  delay: function () { return anime.random(0, 550); },
  opacity: {
    value: [0, 1],
    duration: 600,
    easing: 'easeInOutBack'
  },
  scaleX: {
    value: [0.4, 1]
  },
  scaleY: {
    value: [0.4, 1],
    duration: 1400
  }
}
var swirlyAnimation = {
  duration: 1200,
  elasticity: 700,
  delay: function () { return anime.random(0, 200); },
  opacity: {
    value: [0, 1],
    duration: 600,
    easing: 'easeInOutBack'
  },
  translateX: {
    value: function (el) {
      var middle = ($(el).width() / 2) + $(el).offset().left;
      var distanceFromMiddle = middle - ($(window).width() / 2);
      return distanceFromMiddle * .5 + 'px';
      //anime.random(-100,100);
    }
  },
  translateY: {
    value: function (el) {
      var $el = $(el);
      var $parent = $el.parent();
      var middleParent = $parent.offset().top + ($parent.height() / 2);
      var distanceFromMiddle = $el.offset().top - middleParent;
      //var middle = ($el.height() / 2) + $el.offset().top;
      return distanceFromMiddle * 0.5 + 'px';
      // //
      //return anime.random(-100,100);
    }
  },
  // scaleX: {
  //     value: [0.4,1]
  // },
  // scaleY: {
  //     value: [0.4,1],
  //     duration: 1400
  // }
}

module.exports = { defaultAnimation: defaultAnimation, bouncyAnimation: bouncyAnimation, swirlyAnimation: swirlyAnimation };

},{}],3:[function(require,module,exports){
var component = (function () {
  var $elem = $('.escape-button');
  var cookieName = 'hyh.escape';

  if ($elem.length > 0) {

    var EscapeButton = function ($component) {
      var self = {
        init: function () {
          self.escapeUrl = $component.data('exit-url');

          // if escape button has been clicked, re-exit page, don't allow return
          if (self.getCookie(cookieName)) {
            self.processExit();
            self.removeCookie(cookieName);
          }

          $component.click(function () {
            self.processExit();
          });

          //modify links for escape purposes
          var links = document.getElementsByTagName('a');

          for (var i = 0; i < links.length; i++) {
            links[i].addEventListener('click', self.interceptLink);
          }

          return;
        },
        interceptLink: function (ev) {
          ev.preventDefault();
          var url = ev.currentTarget.href;
          if (!url) {
            console.log('Link is missing valid href', ev.currentTarget);
            return;
          }
          window.location.replace(url);
        },
        getCookie: function (cookieName) {
          var name = cookieName + "=";
          var decodedCookie = decodeURIComponent(document.cookie);
          var ca = decodedCookie.split(';');

          for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return true;
            }
          }
          return false;
        },
        setCookie: function (name) {
          document.cookie = name + "=true; Max-Age=300; path=/";
        },
        removeCookie: function eraseCookie(name) {
          document.cookie = name + '=; Max-Age=-99999999; path=/';
        },
        processBack: function () {
          self.setCookie(cookieName);
        },
        wipePage: function () {
          document.body.innerHTML = '';
        },
        changeLocation: function () {
          window.location.replace(self.escapeUrl);
        },
        openNewTab: function () {
          window.open(self.escapeUrl + '?request=search');
        },
        processExit: function () {
          self.processBack();
          self.wipePage();
          self.changeLocation();
          self.openNewTab();
        },
        unload: function () {
          console.log("App unloaded");

          return;
        }
      }
      return self;
    };
    for (var i = 0; i < $elem.length; i++) {
      var $item = $($elem[i]);
      var escapeButton = new EscapeButton($item);
      escapeButton.init();
    }

  }
})();

},{}],4:[function(require,module,exports){
var component = (function () {
  var $elem = $('.home-escape-message-button');
  var closeClass = 'home-escape-message-close';
  var cookieName = 'bmhyhmessage';

  if ($elem.length > 0) {

    var EscapeMessage = function ($component) {
      var self = {
        init: function () {
          $component.click(function () {
            self.setCookie(cookieName);
            self.hideMessage();
          });
          self.message = $component.parent();

          //check for cookie value
          var hasCookie = self.getCookie(cookieName);

          if (!hasCookie) {
            self.showMessage();
          }

          return;
        },
        hideMessage: function () {
          self.message.addClass(closeClass);
        },
        showMessage: function () {
          self.message.removeClass(closeClass);
        },
        getCookie: function (cookieName) {
          var name = cookieName + "=";
          var decodedCookie = decodeURIComponent(document.cookie);
          var ca = decodedCookie.split(';');

          for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return true;
            }
          }
          return false;
        },
        setCookie: function (name) {
          document.cookie = name + "=true; expires=0; path=/";
        },
        unload: function () {
          console.log("App unloaded");

          return;
        }
      }
      return self;
    };
    for (var i = 0; i < $elem.length; i++) {
      var $item = $($elem[i]);
      var escapeMessage = new EscapeMessage($item);
      escapeMessage.init();
    }

  }
})();

},{}],5:[function(require,module,exports){
//open all external links in new window
$(document).ready(function () {
  window.ExternalLinksNewWindow();
});

window.ExternalLinksNewWindow = function ($parentSelector) {

  if (!$parentSelector) {
    $parentSelector = $('html');
  }
  $parentSelector.find('a:not([href^="http://' + window.location.host + '"]):not([href^="https://' + window.location.host + '"]):not([href^="#"]):not([href^="/"]):not([href^="mailto:"]):not([href^="javascript:void(0);"]):not([href^="javascript:void(0)"])').attr("target", "_blank");
};
},{}],6:[function(require,module,exports){
'use strict';

var glossaryModalSelector = '#GlossaryModal';
var glossaryTerms = [];

window.bm.glossaryTerms = {};

window.bm.glossaryTerms.init = function (pageId) {
  $.ajax({
    url: '/api/glossary/glossaryterms',
    type: "GET",
    dataType: "json",
    contentType: "application/json; charset=utf-8",
    data: { pageId: pageId },
    cache: true,
    success: function (data) {
      glossaryTerms = data;
      bindClicks();
      bindHovers();
    },
    error: function (xhr, textStatus, errorThrown) {
      console.log(xhr);
      console.log("textStatus : " + textStatus);
      console.log("errorThrown : " + errorThrown);
    }
  });
}

window.bm.glossaryTerms.print = function () {
  console.log(glossaryTerms);
}

function bindClicks() {
  $('body').on('click', '.glossary-term-trigger', function (e) {
    e.preventDefault();
    var $this = $(this);

    var $glossaryModal = $(glossaryModalSelector);

    if (!$glossaryModal.hasClass('active-freeze')) {
      $this.addClass('active');

      if (!$glossaryModal.hasClass('active')) {
        showGlossaryModal($this, $glossaryModal, glossaryTerms);
      }

      $glossaryModal.addClass('active-freeze');
    } else {
      $this.removeClass('active');
      hideGlossaryModal($glossaryModal, true);
    }
  });

  $('body').on('click', '.glossary-close', function () {
    hideGlossaryModal($(glossaryModalSelector), true);
  });
}

function bindHovers() {
  $('body').on('mouseenter', '.glossary-term-trigger', function () {
    var $this = $(this);
    var $glossaryModal = $(glossaryModalSelector);

    if ($glossaryModal.hasClass('active-freeze') && !$this.hasClass('active')) {
      $('.glossary-term-trigger.active').removeClass('active');
      $glossaryModal.removeClass('active-freeze');
    }

    $glossaryModal.addClass('active');
    showGlossaryModal($this, $glossaryModal, glossaryTerms);
  });

  $('body').on('mouseleave', '.glossary-term-trigger', function () {
    hideGlossaryModal($(glossaryModalSelector), false);
  });

  $(glossaryModalSelector).hover(function () {
    var $this = $(this);
    var $glossaryModal = $(glossaryModalSelector);
    $glossaryModal.addClass('active');
    if ($this.hasClass('glossary-modal')) {
      $glossaryModal.fadeIn(300);
    }
  }, function () {
    hideGlossaryModal($(glossaryModalSelector), false);
  });
}

function normalizeTerm(term) {
  if (!!term && term.length > 0) {
    return term.toLowerCase().replace('&nbsp;', ' ').trim()
  }

  return '';
}

function showGlossaryModal($this, $glossaryModal, allTerms) {
  var term = normalizeTerm($this.text());
  var displayTerm = '';
  if (term.length > 0) {
    var definition = '';

    for (var i = 0; i < allTerms.length; i++) {
      if (normalizeTerm(allTerms[i].glossaryTerm) == term) {
        definition = allTerms[i].termDefinition;
        displayTerm = allTerms[i].glossaryTerm;
        break;
      }
    }

    if (definition.length > 0) {
      $glossaryModal.find('.glossary-container > .term-text').html(displayTerm);
      $glossaryModal.find('.glossary-container > .term-definition').html(definition);

      var navOffset = 150;

      var windowWidth = $(window).width();
      var glossaryHeight = $glossaryModal.height() + 10;
      var superOffsetTop = $this.offset().top;

      //Gets the space between the clicked term and the top of the window
      var topDiff = superOffsetTop - (window.pageYOffset + glossaryHeight + navOffset);
      var topPos = superOffsetTop - glossaryHeight;

      if (topDiff <= 0) {
        topPos = superOffsetTop + 20;
      } else {
        topPos = topPos + 10;
      }

      if (windowWidth < 768) {
        $glossaryModal.css({ 'left': '2%', 'top': topPos + 'px' });

      } else {

        //get Left position of Definition modal
        var leftPos = $this.offset().left;

        var rightPosModal = leftPos + $glossaryModal.width();
        if (rightPosModal > windowWidth) {
          leftPos = leftPos - (rightPosModal + 40 - windowWidth);
        }

        //if Modal will be off screen position left so entire box is on screen
        $glossaryModal.css({ 'left': leftPos + 'px', 'top': topPos + 'px' });
      }
      $glossaryModal.fadeIn(300);
    }
  }
}

function hideGlossaryModal($glossaryModal, removeClickFreeze) {
  setTimeout(function () { if (!$glossaryModal.hasClass('active') && !$glossaryModal.hasClass('active-freeze')) { $glossaryModal.fadeOut(300); } }, 100);
  $glossaryModal.removeClass('active');

  if (removeClickFreeze) {
    $glossaryModal.removeClass('active-freeze');
  }
}

},{}],7:[function(require,module,exports){
(function (global){(function (){
var component = (function () {
  var doc = global.document || null;
  var $elem = $('.menu-button');
  var responsive = require('./responsive');
  var closeClass = 'close';
  var secondaryClass = "secondary-menu-open";
  var $siteWrapper = $('.site-wrapper');
  if ($elem.length > 0) {

    var Menu = function ($component) {
      var self = {
        init: function () {
          $component.click(function () {
            self.toggleMenu();
          });
          self.openText = $component.data('open-text');
          self.closeText = $component.data('close-text');
          self.linesComponent = $component.find('.menu-lines');
          self.textComponent = $component.find('.menu-text');
          self.$globalMenu = $('.global-menu');
          self.$primaryMenu = $('.primary-menu');
          self.$secondaryMenu = $('.secondary-menu');
          self.$primaryMenu.find('li > a').click(function (e) {
            var $this = $(this);
            if ($this.parent().hasClass('active-page') && !!$this.data('id') && $this.data('id').length > 0) {
              e.preventDefault();
              self.openSecondaryMenu($this);
            }
          });
          self.$secondaryMenu.find('.top-content span').click(self.closeSecondaryMenu);
          var $activePageMenuItem = self.$primaryMenu.find('li.active-page > a');
          if ($activePageMenuItem.length > 0) {
            self.openSecondaryMenu($activePageMenuItem);
          }

          $(window).on('resize', function () {
            if (self.$globalMenu.hasClass('active')) {
              self.toggleMenu();
              $siteWrapper.unbind('click.bodyClickClose');
            }
          });
          return;
        },
        openSecondaryMenu: function ($this) {
          var id = $this.data('id');
          self.$secondaryMenu.find('.main-content[data-id="' + id + '"]').addClass('visible');
          self.$globalMenu.addClass(secondaryClass);
        },
        closeSecondaryMenu: function () {
          self.$globalMenu.removeClass(secondaryClass);
          setTimeout(function () {
            self.$secondaryMenu.find('.main-content').removeClass('visible');
          }, 400);//should be equal to the transition time

        },
        toggleMenu: function () {
          self.linesComponent.toggleClass(closeClass);
          var isMobile = responsive.mobileSized();
          var cssChangeAmount = isMobile ? 80 : 480;
          var cssSuffix = isMobile ? 'vw' : 'px';
          var $header = $siteWrapper.find('.header');
          if (self.linesComponent.hasClass(closeClass)) {
            self.textComponent.text(self.closeText);
            $siteWrapper.css('position', 'fixed');

            self.$globalMenu.addClass('active');
            $header.addClass('menu-active');
            $siteWrapper.animate({ borderSpacing: cssChangeAmount }, {
              step: function (now, fx) {
                var newLeftPosition = (now - cssChangeAmount) + cssSuffix;
                self.$globalMenu.css('left', newLeftPosition);
                $(this).css('transform', 'translateX(' + now + cssSuffix + ')');
              },
              duration: 480
            }, 'ease-in-out');
            setTimeout(function () {
              $siteWrapper.one('click.bodyClickClose', function () {
                if (self.$globalMenu.hasClass('active')) {
                  self.toggleMenu();
                }
              });
            }, 100);
          }
          else {
            self.textComponent.text(self.openText);
            self.$globalMenu.removeClass('active');
            $siteWrapper.animate({ borderSpacing: 0 }, {
              step: function (now, fx) {
                if (now == 0) {
                  $header.removeClass('menu-active');
                }
                var newLeftPosition = (now - cssChangeAmount) + cssSuffix;
                $(this).css('transform', 'translateX(' + now + cssSuffix + ')');
                self.$globalMenu.css('left', newLeftPosition);
              },
              duration: 480,
              complete: function () {
                $siteWrapper.css('transform', '');
                $siteWrapper.css('position', '');
              }
            }, 'ease-in-out');
          }
        },
        unload: function () {
          console.log("App unloaded");

          return;
        }
      }
      return self;
    };
    for (var i = 0; i < $elem.length; i++) {
      var $item = $($elem[i]);
      var menu = new Menu($item);
      menu.init();
    }

  }
})();

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"./responsive":10}],8:[function(require,module,exports){
'use strict';

function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/;";
}

function initializeModalLayout(option) {
  var defaults = {
    currentLanguageCode: "en",
    modalWrapper: '.content-block',
    cookieName: 'disclaimer',
    modalContentClass: 'modal-popup modal-popup--small js-modal',
    modalContentServiceUrl: "/{LanguageKey}AjaxContentPage",
    events: "click.modaldetail tap.modaldetail"
  };

  var hasCookie = document.cookie.match(new RegExp(option.cookieName + '=([^;]+)'));
  if (hasCookie) {
    return;
  }

  var option = $.extend({}, defaults, option),
    $modalWrapper = $(option.modalWrapper),
    modalContentFormat = "<div class=\"{Class}\"></div>";

  $modalWrapper.off(option.events);

  var $body = $('body'),
    $modalBackground = $(".modal-bg");

  if ($modalBackground.length == 0) {
    $modalBackground = $('<div class="modal-bg js-modal-bg"></div>');
  }

  $body.append($modalBackground);

  $body.addClass('is-active-modal');

  var $this = $(this),
    modalQuery = $this.data("modal-query"),
    $modalContent = $(modalContentFormat.replace("{Class}", option.modalContentClass)),
    serviceUrl = option.modalContentServiceUrl.replace("{LanguageKey}", option.currentLanguageCode);

  if (!!modalQuery) {
    serviceUrl += modalQuery;
  }

  $.ajax({
    url: serviceUrl,
    type: "POST",
    dataType: "html",
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    cache: false,
    success: function (data) {
      setCookie(option.cookieName, true, 365);

      $modalContent.append($(data))

      var $closeButton = $('<button type="button" class="modal-close js-modal-close">X</button>');
      $modalContent.append($closeButton);
      $modalContent.find(".js-modal-close").on('click tap', function () {
        $body.removeClass('is-active-modal');
        $this = $(this);
        $modalContent.remove();
        $this.remove();
      });
      $modalContent.find(".js-action-accept").on('click tap', function () {
        $body.removeClass('is-active-modal');
        $this = $(this);
        $modalContent.remove();
        $this.remove();
      });
      $modalBackground.on('click tap', function () {
        $body.removeClass('is-active-modal');
        $this = $(this);
        $modalContent.remove();
        $this.remove();
      });
      $body.append($modalContent);
    },
    error: function (xhr, textStatus, errorThrown) {
      $body.removeClass('is-active-modal');
      console.log(xhr.responseText);
    }
  });
  return false;
}

window.ModalLayout =
{
  Initialize: initializeModalLayout
};
},{}],9:[function(require,module,exports){
(function (global){(function (){
var component = (function () {
  var doc = global.document || null;
  var $elem = $('.grid-animation-js');
  var animation = require('./default-animate').bouncyAnimation;
  if ($elem.length > 0) {
    var Grid = function ($component) {
      var self = {
        init: function () {
          //anime(animation);
          var controller = new ScrollMagic.Controller();
          $('.grid-item-js').each(function () {
            var scene = new ScrollMagic.Scene({
              reverse: false,
              triggerHook: 1, //0 = animate starts at top ofpage, 1 = bottom of page
              triggerElement: this
            }).on('enter', function (event) {
              var item = event.target.triggerElement();
              animation.targets = item;
              anime(animation);
              scene.destroy();
              $(event.target.triggerElement()).parent().addClass('animation-initialized');
            }).addTo(controller);
          });
          return;
        },
        unload: function () {
          return;
        }
      }
      return self;
    };
    for (var i = 0; i < $elem.length; i++) {
      var $item = $($elem[i]);
      var grid = new Grid($item);
      grid.init();
    }

  }
})();

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"./default-animate":2}],10:[function(require,module,exports){
module.exports = {
  mobileSized: function () {
    return $(window).width() < 769
  },
  tabletSized: function () {
    return $(window).width() < 1025
  },
  isMobileDevice: function () {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  },

}

},{}],11:[function(require,module,exports){
(function (global){(function (){
var component = (function () {
  var doc = global.document || null;
  var $elem = $('.word-cloud');
  var animation = require('./default-animate').bouncyAnimation;
  animation.elasticity = 500;
  animation.duration = 1000;
  if ($elem.length > 0) {
    var Cloud = function ($component) {
      var self = {
        init: function () {
          self.$tags = $component.find('.tag');
          self.$container = $component.find('.tag-container');
          self.resize();
          //need to make sure none of the tags are wider than the container, as this can throw off
          //anime(animation);
          var controller = new ScrollMagic.Controller();
          $('.cloud-item-js').each(function () {
            $(this).on('mouseenter', function () {
              self.$container.addClass('container-active');
            });
            $(this).on('mouseleave', function () {
              self.$container.removeClass('container-active');
            });
            $('.cloud-item-js').on('click', function () {
              self.$container.removeClass('container-active');
            });
            var scene = new ScrollMagic.Scene({
              reverse: false,
              triggerHook: 0.8, //0 = animate starts at top ofpage, 1 = bottom of page
              triggerElement: '.tag-container'
            }).on('enter', function (event) {
              var item = $(event.target.triggerElement()).find('.tag');
              animation.targets = '.tag';
              animation.complete = function () {
                $(item).addClass('initialized');
                $(item).css('transform', '');
                $(item).css('opacity', '');
              }
              anime(animation);
              scene.remove();
            }).addTo(controller);
          });
          return;
        },
        resize: function () {
          $.each(self.$tags, function (ndx, item) {
            var $tag = $(item);
            if ($tag.width() > self.$container.width()) {
              var ratio = self.$container.width() / $tag.width();
              var currentFontSize = $tag.css('font-size').replace('px', '');
              $tag.css('font-size', (currentFontSize * ratio - 2) + 'px');
            }
          })
        },
        unload: function () {
          return;
        }
      }
      return self;
    };
    for (var i = 0; i < $elem.length; i++) {
      var $item = $($elem[i]);
      var cloud = new Cloud($item);
      cloud.init();
    }

  }
})();

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{"./default-animate":2}],12:[function(require,module,exports){
var component = (function () {
  var $elem = $('.video-rte a');
  var cookieName = 'hyhhomevideo';

  if ($elem.length > 0) {

    var ModalVideo = function ($component) {
      var self = {
        init: function () {
          $component.click(function () {
            self.openModalVideo();
          });

          // if this is the home page and video hasn't played, play video automatically
          if (!self.getCookie(cookieName)) {
            if ($elem.parents('.video-rte-home').length) {
              self.setCookie(cookieName);
              self.openModalVideo();
            }
          }

          return;
        },
        openModalVideo: function () {

          var defaults = {
            currentLanguageCode: "en",
            //modalWrapper: '.content-block',
            modalWrapper: '.column-main',
            cookieName: 'video',
            modalContentClass: 'video-modal',
            events: "click.modaldetail tap.modaldetail"
          };

          var option = $.extend({}, defaults, option),
            $modalWrapper = $(option.modalWrapper),
            modalContentFormat = "<div class=\"{Class}\"></div>",
            videoSource = $component.data('video-src');

          $modalWrapper.off(option.events);

          var $body = $('body'),
            $modalBackground = $(".modal-bg");

          if ($modalBackground.length == 0) {
            $modalBackground = $('<div class="modal-bg js-modal-bg"></div>');
          }

          $body.append($modalBackground);

          $body.addClass('is-active-modal');

          var $this = $(this),
            $modalContent = $(modalContentFormat.replace("{Class}", option.modalContentClass));

          $videoContent = $('<iframe class="video-rte-iframe" src="' + videoSource + '" frameborder="0" allowfullscreen=""></iframe>');

          $videoContent.height($videoContent.find('iframe').contents().height());

          $modalContent.append($videoContent);

          var $closeButton = $('<button type="button" class="modal-close js-modal-close">X</button>');
          $modalContent.append($closeButton);
          $modalContent.find(".js-modal-close").on('click tap', function () {
            $body.removeClass('is-active-modal');
            $this = $(this);
            $modalContent.remove();
            $this.remove();
          });
          $modalContent.find(".js-action-accept").on('click tap', function () {
            $body.removeClass('is-active-modal');
            $this = $(this);
            $modalContent.remove();
            $this.remove();
          });
          $modalBackground.on('click tap', function () {
            $body.removeClass('is-active-modal');
            $this = $(this);
            $modalContent.remove();
            $this.remove();
          });
          $body.append($modalContent);

        },
        getCookie: function (cookieName) {
          var name = cookieName + "=";
          var decodedCookie = decodeURIComponent(document.cookie);
          var ca = decodedCookie.split(';');

          for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
              c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
              return true;
            }
          }
          return false;
        },
        setCookie: function (name) {
          document.cookie = name + "=true; expires=0; path=/";
        },
        unload: function () {
          console.log("App unloaded");

          return;
        }
      }
      return self;
    };
    for (var i = 0; i < $elem.length; i++) {
      var $item = $($elem[i]);
      var modalVideo = new ModalVideo($item);
      modalVideo.init();
    }

  }
})();

},{}],13:[function(require,module,exports){
$(document).ready(function () {
  require('./app/menu');
  require('./app/animate');
  require('./app/portal-grid');
  require('./app/tag-cloud');
  require('./app/external-links-new-window');
  require('./app/glossary-terms');
  require('./app/modal-layout');
  require('./app/escape-button');
  require('./app/escape-message');
  require('./app/video');

  //Template Only Files Commented Out
  //require('./app/handbook-search');

  //Adds Is-touch if user is using a touch device
  document.addEventListener('touchstart', function addtouchclass(e) { // first time user touches the screen
    document.documentElement.classList.remove('no-touch') // add "can-touch" class to document root using classList API
    document.removeEventListener('touchstart', addtouchclass, false) // de-register touchstart event
  }, false)
});

},{"./app/animate":1,"./app/escape-button":3,"./app/escape-message":4,"./app/external-links-new-window":5,"./app/glossary-terms":6,"./app/menu":7,"./app/modal-layout":8,"./app/portal-grid":9,"./app/tag-cloud":11,"./app/video":12}]},{},[13]);
